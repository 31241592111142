// SCSS for the Invest component

.robodog_owners {
    width: 30rem;
    height: auto;
    display: inline-block;
    margin: 3rem auto;
    position: relative;
}

.robodog_play_ball {
    height: auto;
    width: 43.75rem;
    display: block;
    position: relative;
    margin: 3.125rem auto;
}

.helper_img {
    width: 18.75rem;
    height: auto;
    display: inline-block;
    margin: auto 6.25rem;
    position: relative;
    border-radius: 10%;
}

.sit_dog {
    width: 31.25rem;
    height: auto;
    display: inline-block;
    margin: auto 0.625rem;
}

// Responsive adjustments
@media only screen and (max-width: 768px) {
    // Adjustments for tablet devices

    .helper_img, .sit_dog {
        width: 15rem; // Reduce size
        height: auto; // Adjust height to maintain aspect ratio
        margin: auto 2rem; // Adjust margin
    }

    .robodog_owners {
        width: 20rem;
        margin: 1rem auto;
    }

    .robodog_play_ball {
        width: 35rem; // Reduce size
        top: 6.25rem; // Adjust position
    }
}

@media only screen and (max-width: 480px) {
  // Adjustments for mobile devices

  .helper_img, .sit_dog {
    width: 10rem; // Further reduce size
    margin: 1rem auto; // Further adjust margin
  }

  .robodog_play_ball {
    width: 25rem; // Further reduce size
    top: 3rem; // Adjust position
  }

  .robodog_owners {
      width: 10rem;
      margin: 1rem auto;
  }
}