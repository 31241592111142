// Navigation.scss
// Styles for the main navigation bar at the top of the page.

body {
  padding-top: 6rem; // Height of the navbar
}

nav.navbar {
  padding: 0;
  background-color: #372636;
  height: 7rem; // Fixed height for the navbar
  display: flex;
  justify-content: space-between;
  align-items: center; // Vertically center items
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10; // Increased z-index to ensure navbar is above other content

  .navbar-menu {
    margin-right: 2rem;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .navbar-brand {
    display: flex;
    align-items: center;
    text-decoration: none; // Remove underline from the brand link
    margin-left: 1rem;

    .logo {
      width: 7rem;
      height: auto;
      margin-right: 1rem;
    }
  }

  ul.navbar-nav {
    list-style: none; // Remove default list style
    padding: 0; // Remove default padding
    display: flex;
    align-items: center;

    li {
      a {
        padding: 1rem;
        color: #f5f3ee;
        text-decoration: none; // Remove underline from links
        font-size: 2.0625rem;

        &:hover,
        &.active {
          color: #f8cf79; // Adjusted color without lighten function
        }
      }

      &:hover::after {
        content: ''; // Added missing content property
        display: block; // Ensure the pseudo-element is displayed
        height: 2px; // Set a height for the underline effect
        background-color: #f8cf79; // Set the color for the underline effect
        width: 100%;
      }
    }
  }

  .navbar-toggler {
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    padding: 0.5rem 0.75rem;
    display: none; // will be overridden in media query
  }

  // Hamburger icon bars
  .navbar-toggler-icon {
    display: block;
    width: 1.875rem;
    height: auto;
    background-color: #f8cf79; // Set background color for the middle bar
    position: relative;
    transition: background 0s ease-out;

    &:before,
    &:after {
      content: ''; // Added missing content property
      background-color: #f5f3ee;
      display: block;
      width: 100%;
      height: 0.1875rem; // Ensure height matches the middle bar
      position: absolute;
      transition: all 0.3s ease-out;
    }

    &:before {
      top: -0.5rem; // Adjusted to use 'top' property for positioning
    }

    &:after {
      bottom: -0.3125rem; // Adjusted to use 'bottom' property for positioning
    }
  }
}

@media (max-width: 768px) {
  .navbar-toggler {
    display: block !important;
    margin-right: 2rem;
  }

  .navbar-menu {
    position: absolute;
    background-color: #372636;
    top: 5.3125rem; // 85px converted to rem
    right: auto;
    width: 100%;
    transform: translateX(100%); // Hide the menu off-screen
    transition: transform 0.3s ease-out;

    &.active {
      transform: translateX(0); // Show menu
    }
  }

  ul.navbar-nav {
    flex-direction: column; // Stack the links vertically
    width: 100%;
    background-color: darken(#372636, 10%); // Use SASS function to darken the background

    li {
      border-top: 0.0625rem solid #f8cf79; // 1px converted to rem and add a separator between links
      padding: 2rem;

      &:first-child {
        border-top: none;
      }
    }
  }
}

// Media query for mobile devices
@media (max-width: 480px) {
  body {
    padding-top: 3.4375rem; // 55px converted to rem
  }

  nav.navbar {
    height: 5rem; // 55px converted to rem
  }

  .navbar-brand {
    .logo {
      width: 3.125rem // 50px converted to rem
    }
  }

  ul.navbar-nav li a {
    font-size: 1.25rem; // Adjusted font size for nav links
  }

  .navbar-toggler {
    .navbar-toggler-icon {
      // Adjust the positioning of pseudo-elements for the mobile view
      &:before {
        top: -0.375rem; // 6px converted to rem
      }

      &:after {
        bottom: -0.25rem; // 4px converted to rem
      }
    }
  }
}