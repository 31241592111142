/* index.css */

/* Global body styles */
html, body {
  background: #372636;
  margin: 0; /* Removes default margin from the body */
  /* Define a list of system fonts for the application that works across different operating systems and browsers */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased; /* Apply antialiasing for smoother font rendering in WebKit browsers */
  -moz-osx-font-smoothing: grayscale; /* Apply font smoothing for Firefox on macOS to improve text rendering */
}

/* Global styles for <code> elements */
code {
  /* Define a monospaced font stack for <code>, which is typically used for displaying inline or block code */
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Adjust base font size for mobile devices
@media only screen and (max-width: 480px) {
  html {
    font-size: 56.25%; Reduce the base font-size on mobile for easier calculation of rem units (1rem = 9px) */
