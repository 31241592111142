// Styling for footer with enhancements for interactivity and responsiveness
footer.footer {
  z-index: 3; // Ensure the footer is above other elements
  width: auto; // Full width
  padding: 3rem; // Uniform padding
  line-height: 1.5rem; // Improved line height for text within the footer
  background-color: darken(#372636, 10%); // A darker background for contrast
  text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5); // Text shadow for a slight 3D effect
  display: flex; // Enable Flexbox
  justify-content: center; // Center content horizontally
  align-items: center; // Center content vertically

  .center_text {
    margin: 0 auto;
  }

  .copyright_container {
    margin: 0 auto 1rem;
    text-align: center;
  }

  .footer_icon_container {
    margin: 0 auto;
  }

  // Styling for social media icons
  a svg {
    margin-right: 0.4em; // Space between the icons
    color: #f8cf79; // Icon color
    transition: all 0.2s ease-in-out; // Smooth transition for scaling effect

    // Scale effect on hover and active state for better user feedback
    &:hover,
    &:active {
      transform: scale(1.25);
    }
  }

  // Styling for images (if any are within the footer)
  img {
    width: 15%; // Width for images to be responsive

    // Adjusting image width on smaller screens for better visibility
    @media (max-width: 991px) {
      width: 35%;
    }

    // Specific ID "wink" for an image, potentially a logo with unique styling
    &#wink {
      float: left; // Positioning to the left
      width: 64px; // Fixed width
      margin-top: 0.25em; // Margin for alignment
    }
  }

  // Responsive adjustments for smaller screens
  @media (max-width: 768px) {
    padding: 2rem; // Reduced padding for medium screens
    flex-direction: column; // Stack items vertically on smaller screens

    img {
      width: 50%; // Increase width for better visibility on smaller screens
    }
  }

  @media (max-width: 480px) {
    padding: 1.5rem; // Further reduced padding for small screens
    line-height: 1.2rem; // Adjust line height for better readability

    a svg {
      transform: scale(1); // Default scale on smaller screens
      &:hover,
      &:active {
        transform: scale(1.15); // Slightly smaller scale effect on hover for small screens
      }
    }

    img {
      width: 70%; // Further increase width for very small screens
    }

    // Adjusting the logo with ID "wink" for very small screens
    &#wink {
      width: 48px; // Smaller fixed width
      margin-top: 0; // Adjust margin for alignment
    }
  }
}