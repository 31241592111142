.robodog_chasing_ball {
    height: auto;
    width: 30rem;
    position: relative;
    margin: 5.5rem;
}

/* Responsive adjustments */
@media only screen and (max-width: 768px) {
  .robodog_chasing_ball {
    height: auto;
    width: 15rem;
    position: relative;
    margin: 6.25rem;
  }
}

@media only screen and (max-width: 480px) {

  .robodog_chasing_ball {
    height: auto;
    width: 10rem;
    position: relative;
    margin: 1rem;
  }

  .RoboDog_1 {
    width: 100%; /* Full width for smaller devices */
  }
}