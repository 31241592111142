.spot_v, .our_v, .smell_s, .dog_os {
    width: 27rem;
    height: auto;
    position: relative;
    margin: 3rem;
}

.robo_ear_clip {
    height: auto;
    width: 25rem;
    max-width: 100%;
    position: relative;
    margin: 3.125rem auto;
    display: block;
}

@media (max-width: 768px) {
    .brainbox, .spot_v, .our_v, .smell_s, .dream_img, .dog_os {
        width: 18rem; // Adjusted width for smaller screens
        margin: 2rem; // Smaller margin
    }

    .robo_ear_clip {
        width: 18rem; // Adjusted width for smaller screens
        margin: 2rem auto; // Smaller margin
    }
}

// For screens smaller than or equal to 480px
@media (max-width: 480px) {
    .brainbox, .spot_v, .our_v, .smell_s, .dream_img, .dog_os {
        width: 15rem; // Further reduced width for very small screens
        margin: 1rem; // Further reduced margin
    }

    .robo_ear_clip {
        width: 15rem; // Further reduced width for very small screens
        margin: 1rem auto; // Reduced margin
    }
}
