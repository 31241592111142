$footer-height: 5rem; // 80px

$primary-color: #007bff; // Bootstrap primary color, change as needed
$gray-600: #6c757d; // Bootstrap gray, change as needed

.Page_Title {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  margin: 4.6rem 0;
}

.contact-form-wrapper {
  height: auto;
  display: flex;
  margin: 0.5rem;
  align-items: center;
  justify-content: center;

}

.text-container {
  font-size: 1.875rem; // 30px
  width: 100%;
  max-width: 65rem;
  padding: 0.625rem; // 10px
  position: relative;
  line-height: 2;
  color: inherit;
  margin: 6.25rem; // 100px

}

.form-container {
  width: 100%;
  max-width: 50rem; // No change required, already in rem
  padding: 0.625rem; // 10px
  position: relative;
  line-height: 2;
  color: inherit;
  margin: 6.25rem; // 100px
}

.contact-form-container {
  background-color: transparent;
  padding: 2rem;
  margin-bottom: $footer-height;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

form {
  display: block;
}

.form-group {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

label {
  font-size: 1.3rem;
  color: #f8cf79;
  display: inline-block;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

/* Style for input with error */
.input-error {
  border: 1px solid red; /* Example style for input error */
}

/* Style for error message */
.error-message {
  color: gold; /* Sets the text color to gold */
  /* Additional styles can be added as needed */
}

input[type="text"],
input[type="email"],
textarea {
  display: block;
  width: 93%;
  padding: 0.375rem 0.75rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-clip: padding-box;
  border: 1px;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: $gray-600;
    background-color: #fff;
    border-color: $primary-color;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.25);
  }
}

button[type="submit"] {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-clip: padding-box;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    color: #fff;
    background-color: darken($primary-color, 7.5%);
    border-color: darken($primary-color, 10%);
  }
}

@media only screen and (max-width: 1200px) {
  .contact-form-wrapper {
    flex-direction: column;
  }
}

// Media query for tablets and similar devices
@media only screen and (max-width: 768px) {
  .Page_Title,
  .text-container,
  .form-container {
    margin: 3rem 0; // Adjusted margin
  }
  .contact-form-wrapper {
    flex-direction: column;
  }

  .text-container,
  .form-container {
    padding: 0.5rem; // Adjusted padding
  }
}

// Media query for mobile devices
@media only screen and (max-width: 480px) {
  .Page_Title,
  .form-container {
    margin: 2rem 0; // Further adjusted margin
  }

  .contact-form-wrapper {
    flex-direction: column;
    margin: 1rem;
  }

  .text-container{
    padding: 0.25rem;
    text-align: center;
  }

  .form-container {
    padding: 0.25rem; // Further adjusted padding
  }

  .contact-form-container {
    padding: 1rem; // Adjusted padding
  }

  .contact-form-container form button[type="submit"] {
    padding: 0.3rem 0.6rem; // Adjusted padding
  }
}

@media only screen and (max-width: 320px){
  .text-container{
    text-align: center;
  }
}
