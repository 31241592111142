// App.scss
// Global styles for the application, including layout, typography, and form inputs.

//Skeleton css start----------------------------------------------------
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: auto;
  overflow: hidden;
}

#intro {
  padding-top: 6rem;
  flex: 1; // This ensures that the content section takes up the available space pushing the footer down
}

html, body {
  font-size: 62.5%; // Set the base font-size to 10px for easier calculation of rem units (1rem = 10px)
  height: 100%; // Ensure the html and body tags fill the browser height
  margin: 0; // Reset any default margins
  padding: 0; // Reset any default padding
  background-image: url("../src/assets/img/wine_stain.png"); // Set your background image
  background-size: cover; // Ensure the image covers the full viewport
  background-position: center; // Center the background image
  background-repeat: no-repeat; // The image should not repeat
  background-attachment: fixed; // The background image should be fixed during scrolling
  // Reset tap highlight color
  *:not(input),
  *:not(textarea) {
  -webkit-tap-highlight-color: transparent;
  }
}

body {
  font-size: 1.6rem;
}
//Skeleton css start----------------------------------------------------

//Javascript css start----------------------------------------------------
/* CSS transitions for fade effect */
.fade-container {
  transition: opacity 0.8s ease, transform 0.8s ease;
  opacity: 0;
}

.fade-container.left {
  transform: translateX(-100px); /* Start from the left */
}

/* Class to be toggled for visibility */
.visible {
  opacity: 1;
  transform: none; /* Reset any translation */
}
//Javascript css end------------------------------------------------------

//Button css start----------------------------------------------------
// Override default browser styles for focused buttons and inputs
button:focus,
input:focus {
  outline: none; // Removes the default focus outline for a cleaner look
}

.button_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0;
    height: 6.25rem;
}

.back-btn {
    position: relative;
    padding: 10px;
    background-color: transparent;
    color: #f8cf79;
    text-decoration: none;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 5px;
    outline: none;
    top: 0;
    display: inline-block;
    overflow: hidden;
    font-size: 1.875rem;
}

.back-btn:after {
    content: '';
    position: absolute;
    width: 0;
    height: 5px;
    bottom: 0;
    left: 0;
    background-color: #f8cf79;
    transition: width 0.3s ease;
}

.back-btn:hover::after {
    width: 100%;
}

.learn-more-btn {
    position: relative;
    padding: 0.625rem;
    background-color: transparent;
    color: #cbc2a3;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 1.875rem;
    transition: all 0.3s ease;
    border-radius: 0.3125rem;
    outline: none;
    top: 0;
    display: inline-block;
    overflow: hidden;
}

.learn-more-btn:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0.3125rem;
    bottom: 0;
    left: 0;
    background-color: #f8cf79;
    transition: width 0.3s ease;
}

.learn-more-btn:hover::after {
    width: 100%;
}

.paw {
    width: 6.25rem;
    height: auto;
    margin-right: 0.625rem;
}

.btn-info {
  color: #f5f3ee;
}

.btn.btn-outline-info:hover,
.btn.btn-outline-info:active {
  color: #ffffff !important;
}
//Button css end------------------------------------------------------

//Content tag css start----------------------------------------------------
@font-face {
  font-family: 'Roboto', sans-serif;
}

.company_font_with_gold_coloring{
  font-family: "Felix Titling", sans-serif;
  color: #f8cf79;
  font-size: 3rem;
}

h1 {
    font-family: "Felix Titling", sans-serif;
    text-align: center;
    font-size: 6rem;
    color:#f8cf79;
}

h2 {
  font-size: 4rem;
  color: #f8cf79;
}

h3 {
    font-size: 3rem;
    color:#f8cf79;
    position: relative;
}

p {
  color:#ffcc66;
  font-size: 2.5rem;
  line-height: 2;
}

em {
  color:#ffcc66;
  font-size: 1.6rem;
}
//Content tag css end------------------------------------------------------

//Overlay css start----------------------------------------------------
.overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    padding: 1.25rem;
    border: 0.0625rem solid #ccc; /* Border for visibility */
    background-color: transparentize(#372636, 0.08);
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); /*Shadow for aesthetics */
    z-index: 1001;
}

.T_overlay {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow-x: hidden;
}

.overlay_content_parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    color: #cbc2a3;
    margin: 0 2rem;
}
//Overlay css end------------------------------------------------------

//Standard image/assets css start----------------------------------------------------
.Dads_title_page_img {
    width: auto; /* or any other percentage or fixed width you prefer */
    height: 80vh; /* height will be determined by the aspect ratio */
    position: relative;
    object-fit: contain; /* this ensures the image scales within the container without distortion */
}

.Dads_rectangular_img {
    width: auto;
    height: 50vh;
    object-fit: contain;
}

.Dads_rectangular_img_overlay {
    width: auto;
    height: 40vh;
    object-fit: contain;
}

.Dads_square_img {
    width: auto;
    height: 50vh;
    object-fit: contain;
}

.Dads_square_img_overlay {
    width: auto;
    height: 40vh;
    object-fit: contain;
}

.Dads_square_media {

}

.Dads_rectangular_media {

}

.FontIcon {
  color: #f8cf79;
  height: 10rem;
  width: auto;
  margin: 10rem;
}
//Standard image/assets css end------------------------------------------------------

//Page layout css start----------------------------------------------------
.animation-button {
    height: auto;
    display: flex;
    width: 100%;
    margin: 4rem 0;
    align-items: center;
    justify-content: center;
}

.bone {
    width: 6.25rem;
    height: auto;
    display: inline-block;
    margin: 5rem;
}

.bone_container {
    position: relative;
    width: 100%;
    text-align: center;
    margin: 3.75rem;
}


.big_statement_container {
    flex: 1;
    height: auto;
    width: 80%;
    margin: auto;
    position: relative;
    align-items: center;
    justify-content: center;
    contain: content;
}

.header_paired_with_text_right {
    flex: 1;
    max-width: 60rem;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: auto 6.25rem;
    margin-right: 20rem;
    display: inline-block;
    contain: content;
}

.header_paired_with_text_left {
    flex: 1;
    max-width: 60rem;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: auto 6.25rem;
    margin-left: 20rem;
    display: inline-block;
    contain: content;
}

.header_paired_with_image, .header_paired_with_text {
    flex: 1;
    max-width: 60rem;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: auto 6.25rem;
    display: inline-block;
    contain: content;
  }

.page_title {
    width: 100%;
    position: relative;
    margin: 3.125rem 0;
}

.responsive-image {
    flex: 1;
    align-self: center; /* Vertically center the child content */
    position: relative;
    width: 100%; /* Takes full width of the parent */
    height: auto; /* Height will be determined by the content */
    box-sizing: border-box;
    text-align: center; /* Centers inline-elements or text inside the container */
    padding-left: 1rem;
    padding-right: 1rem;
}

.responsive-image_overlay {
    flex: 1;
    align-self: center; /* Vertically center the child content */
    position: relative;
    width: 100%; /* Takes full width of the parent */
    height: auto; /* Height will be determined by the content */
    box-sizing: border-box;
    text-align: center; /* Centers inline-elements or text inside the container */
    padding-left: 1rem;
    padding-right: 1rem;
}

.responsive-title {
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  height: auto;
  width: 100%;
}

.section_title {
    position: relative;
    margin: 3.125rem auto;
    padding: 0.3125rem;
    width: 100%;
    display: block;
}

.text_content_horizontal_large {
    position: relative;
    width: 100%;
    text-align: center;
    height: auto;
    box-sizing: border-box;
    display: inline-block;
}

.text_content_horizontal_small {
    flex: 1;
    position: relative;
    width: 100%;
    text-align: center;
    height: auto;
    box-sizing: border-box;
    display: inline-block;
}

.text_content_vertical_large {
    flex: 1;
    width: 100%;
    max-width: 80rem;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: 0.625rem;
}

.text_content_vertical_small {
    flex: 1;
    width: 100%;
    max-width: 43rem;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: 6rem;
}

.use_case_pitch_horizontal {
    height: auto;
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.use_case_pitch_vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: auto;
    margin: 6.25rem;
}

.title_with_img_vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: auto;
    height: 100vh;
}

.first_wrapper{
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 15rem;
}

.wrapper {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    margin: 15rem 0;
}
//Page layout css end------------------------------------------------------

// Media queries for responsiveness
@media (min-width: 3840px) {
  //Content tag css start-------------------------------------------------------------------------------
  .company_font_with_gold_coloring{
    font-family: "Felix Titling", sans-serif;
    color: #f8cf79;
    font-size: 6rem;
  }

  p {
    font-size: 4rem;
  }
  //Content tag css end----------------------------------------------------------------------------------
  //Page layout css start------------------------------------------------------

  //Page layout css end--------------------------------------------------------
}

@media (min-width: 481px) and (max-width: 768px) {
  //Overlay css start-------------------------------------------------

  .overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: auto;
    padding: 1rem;
    border: 0.0625rem solid #ccc; /* Border for visibility */
    background-color: transparentize(#372636, 0.08);
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); /*Shadow for aesthetics */
    z-index: 1001;
  }

  .Dads_square_img_overlay {
      width: auto;
      height: 40vh;
      object-fit: contain;
  }
  //Overlay css end---------------------------------------------------

  //Content tag css start--------------------------------------------------------------

  h1 {
      font-family: "Felix Titling", sans-serif;
      text-align: center;
      font-size: 3.125rem;
      color:#f8cf79;
  }

  h2 {
    font-size: 2.5rem;
    color: #f8cf79;
  }

  h3 {
      font-size: 2rem;
      color:#f8cf79;
      position: relative;
  }

  p, em {
    font-size: 2rem;
    color:#ffcc66;
  }
  //Content tag css end----------------------------------------------------------------


  //Button css start----------------------------------------------------
  .button_container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 3rem 0;
      height: 6.25rem;
  }

  .learn-more-btn {
    position: relative;
    padding: 0.625rem;
    background-color: transparent;
    color: #cbc2a3;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    transition: all 0.3s ease;
    border-radius: 0.3125rem;
    outline: none;
    top: 0;
    display: inline-block;
    overflow: hidden;
  }

  .paw {
      width: 4.375rem;
      height: auto;
      margin-right: 0.625rem;
  }
  //Button css end------------------------------------------------------

  //Standard image/assets css start--------------------------------------------------------------

  .FontIcon {
    color: #f8cf79;
    height: 7rem;
    width: auto;
    margin: 1rem;
  }

  .Dads_title_page_img {
    width: 100%;
    height: auto;
    position: relative;
    object-fit: contain;
  }

  .Dads_rectangular_img_overlay {
    width: 100%;
    height: 21vh;
    object-fit: contain;
  }

  .Dads_rectangular_img {
      width: auto;
      height: 30vh;
      object-fit: contain;
  }
  //Standard image/assets css end----------------------------------------------------------------

  //Page layout css start-------------------------------------------------------

  .bone {
    width: 4rem;
    height: auto;
    display: inline-block;
    margin: 0 2rem;
  }

  .header_paired_with_image, .header_paired_with_text {
    flex: 1;
    max-width: 46rem;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: 2rem;
    display: inline-block;
    contain: content;
  }

  .header_paired_with_text_left {
    flex: 1;
    max-width: 46rem;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: 2rem 2rem 2rem 4rem;
    display: inline-block;
    contain: content;
  }

  .header_paired_with_text_right {
    flex: 1;
    max-width: 46rem;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: 2rem;
    margin-right: 2rem;
    display: inline-block;
    contain: content;
  }

  .text_content_vertical_small {
    flex: 1;
    width: 100%;
    max-width: 30rem;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: 2rem;
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    margin: 10rem 0;
  }

  .section_title {
    position: relative;
    margin: 3.125rem auto;
    padding: 0.3125rem;
    width: 100%;
    display: block;
    max-width: 90%;
  }

  .use_case_pitch_horizontal {
    height: auto;
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
  }
  //Page layout css end------------------------------------------------------
}

@media (min-width: 321px) and (max-width: 480px) {

  //Overlay css start----------------------------------------------------------------

  .overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: auto;
    padding: 0.5rem;
    border: 0.0625rem solid #ccc; /* Border for visibility */
    background-color: transparentize(#372636, 0.08);
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); /*Shadow for aesthetics */
    z-index: 1001;
  }
  //Overlay css end------------------------------------------------------------------

  //Button css start----------------------------------------------------

  .button_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    height: 6.25rem;
  }

  .learn-more-btn {
    position: relative;
    padding: 0.625rem;
    background-color: transparent;
    color: #cbc2a3;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    transition: all 0.3s ease;
    border-radius: 0.3125rem;
    outline: none;
    top: 0;
    display: inline-block;
    overflow: hidden;
  }

  .paw {
    width: 3.125rem;
    height: auto;
    margin-right: 0.625rem;
  }
  //Button css end------------------------------------------------------

  //Standard image/assets css start----------------------------------------------------
  .Dads_title_page_img {
    width: 100%;
    height: auto;
    position: relative;
    object-fit: contain;
  }

  .Dads_rectangular_img {
      width: auto;
      height: 25vh;
      object-fit: contain;
  }

   .Dads_rectangular_img_overlay {
      width: 100%;
      height: 21vh;
      object-fit: contain;
  }

  .Dads_square_img {
    width: auto;
    height: 25vh;
    object-fit: contain;
  }

  .Dads_square_img_overlay {
      width: auto;
      height: 21vh;
      object-fit: contain;
  }
  //Standard image/assets css end------------------------------------------------------

  //Page layout css start----------------------------------------------------

  .bone {
    width: 3rem;
    height: auto;
    display: inline-block;
    margin: 0 1rem;
  }

  .header_paired_with_image, .header_paired_with_text {
    flex: 1;
    max-width: 30rem;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: auto 1rem;
    display: inline-block;
    contain: content;
  }

  .header_paired_with_text_right {
    flex: 1;
    max-width: 46rem;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: 2rem;
    margin-right: 2rem;
    display: inline-block;
    contain: content;
  }

  .header_paired_with_text_left {
    flex: 1;
    max-width: 46rem;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: 2rem 2rem 2rem 4rem;
    display: inline-block;
    contain: content;
  }

  .text_content_vertical_large, .text_content_vertical_small {
    flex: 1;
    width: 100%;
    max-width: 30rem;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: 1rem;
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    margin: 7rem 0;
  }

  .use_case_pitch_horizontal {
    height: auto;
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
  }
//Page layout css end------------------------------------------------------

//Content tag css start-------------------------------------------------------------------------------

  h1 {
      font-family: "Felix Titling", sans-serif;
      text-align: center;
      font-size: 3.125rem;
      color:#f8cf79;
  }

  h2 {
    font-size: 2rem;
    color: #f8cf79;
  }

  p, em {
    font-size: 1.5rem;
    color:#ffcc66;
  }
//Content tag css end-------------------------------------------------------------------------------
}

@media only screen and (max-width: 320px) {
  //Content tag css start-------------------------------------------------------------------------------
  .company_font_with_gold_coloring{
    font-family: "Felix Titling", sans-serif;
    color: #f8cf79;
    font-size: 2rem;
  }

  h1 {
    font-family: "Felix Titling", sans-serif;
    text-align: center;
    font-size: 3rem;
    color:#f8cf79;
  }

  h2 {
    font-size: 2rem;
    color: #f8cf79;
  }

  h3 {
    font-size: 2rem;
    color:#f8cf79;
    position: relative;
  }

  p, em {
    font-size: 1.4rem;
    color:#ffcc66;
    line-height: 1;
  }
  //Content tag css end----------------------------------------------------------------------------------
  //Button css start----------------------------------------------------
   .learn-more-btn {
      position: relative;
      padding: 0.625rem;
      background-color: transparent;
      color: #cbc2a3;
      text-decoration: none;
      border: none;
      cursor: pointer;
      font-size: 1.5rem;
      transition: all 0.3s ease;
      border-radius: 0.3125rem;
      outline: none;
      top: 0;
      display: inline-block;
      overflow: hidden;
    }
  //Button css end----------------------------------------------------
  //Page layout css start--------------------------------------------------------------------------------
  .use_case_pitch_vertical {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      height: auto;
      margin: .5rem;
  }

  .header_paired_with_text_right {
    flex: 1;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: 2rem;
    margin-right: 2rem;
    display: inline-block;
    contain: content;
  }

  .header_paired_with_text_left {
    flex: 1;
    text-align: center;
    position: relative;
    line-height: 2;
    margin: 2rem 2rem 2rem 4rem;
    display: inline-block;
    contain: content;
  }

  .use_case_pitch_horizontal {
    height: auto;
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    margin: 3rem 0;
  }
  //Page layout css end----------------------------------------------------------------------------------
  //Standard image/assets css start----------------------------------------------------------------------
  .Dads_title_page_img {
    width: 100%;
    height: 50vh;
    position: relative;
    object-fit: contain;
  }

  .Dads_rectangular_img {
      width: auto;
      height: 20vh;
      object-fit: contain;
  }

   .Dads_rectangular_img_overlay {
      width: 100%;
      height: 20vh;
      object-fit: contain;
   }

  .Dads_square_img {
    width: auto;
    height: 17vh;
    object-fit: contain;
  }

  .Dads_square_img_overlay {
    width: auto;
    height: 17vh;
    object-fit: contain;
  }

  .FontIcon {
    color: #f8cf79;
    height: 4rem;
    width: auto;
    margin: 1rem;
  }

  .bone {
    width: 3rem;
    height: auto;
    display: inline-block;
    margin: 0 1rem;
  }

  .paw {
    width: 3.125rem;
    height: auto;
    margin-right: 0.625rem;
  }
  //Standard image/assets css end----------------------------------------------------


}